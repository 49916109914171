import Appsignal from "@appsignal/javascript";

// To appease the typscript gods
declare global {
  interface Window {
    APP_SIGNAL_FRONTEND_KEY: string;
    APP_SIGNAL_REVISION: string;
  }
}

export const appsignal = new Appsignal({
  key: window.APP_SIGNAL_FRONTEND_KEY, // front-end specific key; safe to expose
  revision: window.APP_SIGNAL_REVISION, // the revision of the release
});
