import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="invoice-percentage-calculator"
export default class extends Controller {
  static targets = ["totalPrice", "result", "percentage"];
  declare readonly totalPriceTarget: HTMLElement;
  declare readonly resultTarget: HTMLSelectElement;
  declare readonly percentageTarget: HTMLSelectElement;

  connect() {
    this.calculate();
  }

  calculate() {
    if (this.totalPriceTarget.textContent) {
      const totalPrice = parseFloat(
        this.totalPriceTarget.textContent.replace(/[^0-9.-]+/g, ""),
      );
      const percentage = parseFloat(this.percentageTarget.value);

      if (isNaN(percentage)) {
        this.resultTarget.innerHTML = "$--.--";
        return;
      }

      const result = totalPrice * (percentage / 100);
      this.resultTarget.innerHTML = `$${result.toFixed(2)}`;
    }
  }
}
