import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "button"];
  declare readonly sourceTarget: HTMLInputElement;
  declare readonly buttonTarget: HTMLElement;

  copy() {
    // Optionally copy from a <div data-copy="..."> instead of the input value
    const text = this.sourceTarget.dataset.copy || this.sourceTarget.value;
    navigator.clipboard.writeText(text);

    // flash green to indicate success
    this.buttonTarget.classList.remove("hover:text-indigo-500");
    this.buttonTarget.classList.add("hover:text-green-500");
    setTimeout(() => {
      this.buttonTarget.classList.add("hover:text-indigo-500");
      this.buttonTarget.classList.remove("hover:text-green-500");
    }, 200);
  }
}
