import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit", "suggestion", "suggestionContainer"];
  declare readonly inputTarget: HTMLTextAreaElement;
  declare readonly submitTarget: HTMLButtonElement;
  declare readonly suggestionTarget: HTMLDivElement;
  declare readonly suggestionContainerTarget: HTMLDivElement;

  handleInput(event: Event) {
    this.submitTarget.disabled = this.inputTarget.value.length === 0;
    this.changeRowsToFitContent();
  }

  autofillWithSuggestion(event: Event) {
    if (this.suggestionTarget.innerText) {
      const value = this.suggestionTarget.innerText.trim();
      this.inputTarget.value = value;
      this.submitTarget.disabled = false;

      this.changeRowsToFitContent();

      // Hide the suggestion
      this.suggestionContainerTarget.classList.add("hidden");

      // Move focus to the end of the input
      this.inputTarget.focus();
    }
  }

  changeRowsToFitContent() {
    this.inputTarget.style.height = "auto";
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
  }
}
