import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  declare readonly formTarget: HTMLFormElement;

  clickYes() {
    this.formTarget.classList.remove("hidden");
  }

  clickNo() {
    this.formTarget.classList.add("hidden");
  }
}
