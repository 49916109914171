import { Controller } from "@hotwired/stimulus";

/**
 * Synchronizes the start and end times of a time interval to spare users from
 * having to move both the start and end time when rescheduling an event.
 */
export default class extends Controller<HTMLDivElement> {
  static targets = ["start", "end"];
  declare readonly startTarget: HTMLSelectElement;
  declare readonly endTarget: HTMLSelectElement;
  delta = 0;
  FIFTEEN_MINUTES_INCREMENTS_IN_A_DAY = 96;

  connect() {
    this.computeDelta();
  }

  computeDelta = () => {
    this.delta = this.endTarget.selectedIndex - this.startTarget.selectedIndex;
  };

  sync = () => {
    this.endTarget.selectedIndex =
      (this.startTarget.selectedIndex + this.delta) %
      this.FIFTEEN_MINUTES_INCREMENTS_IN_A_DAY;
  };
}
