import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Apple does not support clearing the badge in the background, so we need to clear it when the user opens the app
    // If the user is navigating through the app, we can assume that they saw the notifications
    // If the navigator has clearAppBadge method (meaning this is a PWA), then call it
    if (navigator.clearAppBadge) {
      navigator.clearAppBadge();
    }
  }
}
