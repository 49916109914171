import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "menu",
    "searchItem",
    "editableName",
    "readOnlyName",
    "editableUnit",
    "readOnlyUnit",
    "editablePrice",
    "editablePriceContainer",
    "readOnlyPrice",
    "editMaterialLink",
    "materialId",
    "updatingUnit",
    "formWrapper",
  ];
  declare readonly inputTarget: HTMLInputElement;
  declare readonly menuTarget: HTMLElement;
  declare readonly searchItemTarget: HTMLElement;
  declare readonly editableNameTarget: HTMLElement;
  declare readonly readOnlyNameTarget: HTMLElement;
  declare readonly editableUnitTarget: HTMLElement;
  declare readonly readOnlyUnitTarget: HTMLElement;
  declare readonly editablePriceTarget: HTMLInputElement;
  declare readonly editablePriceContainerTarget: HTMLElement;
  declare readonly readOnlyPriceTarget: HTMLElement;
  declare readonly editMaterialLinkTargets: HTMLAnchorElement[];
  declare readonly materialIdTarget: HTMLInputElement;
  declare readonly updatingUnitTarget: HTMLElement;
  declare readonly formWrapperTarget: HTMLElement;

  highlightIndex: number | null = null;

  // *********** Event Callbacks ***********

  inputChanged() {
    const searchText = this.inputTarget.value;

    this.filterMenuByText(searchText);
    this.updateSearchItem(searchText);

    this.highlightIndex = 0; // highligh the first item
    this.updateHighlightDisplay();
  }

  inputFocus() {
    this.showMenu();
  }

  mouseOverItem(event: MouseEvent) {
    const item = event.currentTarget as HTMLElement;
    const menuItems = this.visibleItems();
    this.highlightIndex = Array.prototype.indexOf.call(menuItems, item);
    this.updateHighlightDisplay();
  }

  clickExistingItem(event: MouseEvent) {
    event.preventDefault();
    const item = event.currentTarget as HTMLElement;
    this.selectExistingItem(item);
  }

  clickSearchItem(event: MouseEvent) {
    event.preventDefault();
    this.selectSearchItem();
  }

  clickWindow(event: MouseEvent) {
    if (
      this.menuTarget.contains(event.target as HTMLElement) ||
      this.inputTarget.contains(event.target as HTMLElement)
    ) {
      return;
    }

    this.hideMenu();
  }

  inputKeyDown(event: KeyboardEvent) {
    if (event.key == "ArrowDown") {
      if (this.highlightIndex == null) {
        this.highlightIndex = 0;
        this.updateHighlightDisplay();
        return;
      }

      if (this.highlightIndex < this.visibleItems().length - 1) {
        this.highlightIndex += 1;
        this.updateHighlightDisplay();
      }
    }

    if (event.key == "ArrowUp") {
      if (this.highlightIndex == null) {
        return;
      }

      if (this.highlightIndex == 0) {
        this.highlightIndex = null;
        this.updateHighlightDisplay();
        return;
      }

      if (this.highlightIndex > 0) {
        this.highlightIndex -= 1;
        this.updateHighlightDisplay();
      }
    }

    if (event.key == "Enter") {
      event.preventDefault();
      this.selectFromHighlight();
    }

    if (event.key == "Tab") {
      this.selectFromHighlight();
    }
  }

  unitChanged() {
    const inputValue = this.editableUnitTarget.querySelector("input")?.value;
    if (inputValue !== undefined) {
      this.updatingUnitTarget.innerText = inputValue;
    }
  }

  // *********** Internal Methods ***********

  showMenu() {
    this.menuTarget.classList.remove("hidden");
    this.menuTarget.classList.add("flex");
  }

  hideMenu() {
    this.menuTarget.classList.remove("flex");
    this.menuTarget.classList.add("hidden");
  }

  roundItemCorners() {
    // remove all rounding to get a clean baseline
    this.allItems().forEach((item) => {
      item.classList.remove("rounded-t-md", "rounded-b-md");
    });

    const visibleItems = this.visibleItems();

    // this shouldn't happen, but let's keep TS happy
    if (visibleItems.length == 0) {
      return;
    }

    // round the top of the first item
    const topItem = visibleItems[0];
    topItem.classList.add("rounded-t-md");

    // round the bottom of the last item, too
    const bottomItem = visibleItems[visibleItems.length - 1];
    bottomItem.classList.add("rounded-b-md");
  }

  allItems() {
    return this.menuTarget.querySelectorAll("li");
  }

  allExistingItems() {
    return this.menuTarget.querySelectorAll("li:not(#search-item)");
  }

  visibleItems() {
    return this.menuTarget.querySelectorAll("li:not(.hidden)");
  }

  filterMenuByText(searchText: string) {
    const menuItems = this.allExistingItems();

    const emptyInput = this.inputIsEmpty();

    menuItems.forEach((item) => {
      const itemText = item.textContent?.toLowerCase();

      // two things going on here:
      // 1. Show all items if search is empty
      // 2. Make TS happy by guarding against an undefined item
      if (
        emptyInput ||
        itemText == undefined ||
        itemText.includes(searchText.toLowerCase())
      ) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }

  inputIsEmpty(): boolean {
    return this.inputTarget.value.length == 0;
  }

  updateSearchItem(searchText: string) {
    // if there is no search text, hide the search item
    if (searchText.length == 0) {
      this.searchItemTarget.classList.add("hidden");
      return;
    }

    const displayText = `Add: ${searchText}`;
    this.searchItemTarget.textContent = displayText;
    this.searchItemTarget.classList.remove("hidden");

    this.roundItemCorners();
  }

  updateHighlightDisplay() {
    // first, un-highlight all the items
    this.allItems().forEach((item) => {
      item.classList.remove("bg-indigo-600", "text-white");
    });

    // next, update the highlighted item
    this.visibleItems().forEach((item, index) => {
      if (index == this.highlightIndex) {
        item.classList.add("bg-indigo-600", "text-white");
      }
    });
  }

  selectExistingItem(item: HTMLElement) {
    const materialId = item.dataset.materialId || "";
    const materialUnit = item.dataset.materialUnit || "";
    const materialPrice = +(item.dataset.materialPrice || "");
    const materialName = item.textContent || "";

    this.readOnlyPriceTarget.textContent = "$" + materialPrice.toFixed(2);
    this.readOnlyPriceTarget.classList.remove("hidden");
    this.editablePriceContainerTarget.classList.add("hidden");
    this.editablePriceTarget.value = materialPrice.toFixed(2);

    this.readOnlyUnitTarget.textContent = materialUnit;
    this.updatingUnitTarget.textContent = materialUnit;
    this.readOnlyUnitTarget.classList.remove("hidden");
    this.editableUnitTarget.classList.add("hidden");

    // this allows us to edit materials uncommited material requirements
    this.editMaterialLinkTargets.forEach((link) => {
      link.classList.remove("hidden");
      link.href = link.dataset.editUrl?.replace("REPLACE", materialId) ?? "";
    });

    this.formWrapperTarget.id = `unsaved_material_${materialId}`;

    this.materialIdTarget.value = materialId;

    this.hideMenu();

    this.readOnlyNameTarget.classList.remove("hidden");
    this.readOnlyNameTarget.textContent = materialName;
    this.editableNameTarget.classList.add("hidden");
  }

  selectSearchItem() {
    this.hideMenu();
    this.moveFocusToUnit();
  }

  selectFromHighlight() {
    if (this.highlightIndex == null) {
      return;
    }
    const highlightedItem = this.visibleItems()[
      this.highlightIndex
    ] as HTMLElement;

    if (highlightedItem.hasAttribute("data-material-id")) {
      this.selectExistingItem(highlightedItem);
    } else {
      this.selectSearchItem();
    }
  }

  moveFocusToUnit() {
    this.editableUnitTarget.querySelector("input")?.focus();
  }
}
