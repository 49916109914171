import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["select"];
  declare readonly selectTarget: HTMLSelectElement;

  static values = { url: String };
  declare urlValue: string;

  handleSelectChange(event: CustomEvent) {
    const selectedValue = event.detail.value;

    const url = new URL(this.urlValue);
    url.searchParams.set("month", selectedValue);
    // turn url to string
    Turbo.visit(url.toString());
  }
}
