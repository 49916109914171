import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller<HTMLAnchorElement> {
  static targets = ["textToImprove", "improveLink", "error", "generateLink"];
  declare readonly textToImproveTarget: HTMLInputElement;
  declare readonly errorTarget: HTMLInputElement;
  declare readonly improveLinkTarget: HTMLLinkElement;
  declare readonly generateLinkTarget: HTMLLinkElement;

  get textToImprove() {
    return this.textToImproveTarget.value;
  }

  get generateLinkPath() {
    return this.generateLinkTarget.href;
  }

  get lineItemTitle() {
    const titleElement = this.element?.querySelector("#line_item_title");

    if (!titleElement) {
      return "";
    }

    return (titleElement as HTMLInputElement).value;
  }

  get improveLinkPath() {
    return this.improveLinkTarget.href;
  }

  async generate() {
    if (this.lineItemTitle.trim() === "") {
      this.errorTarget.classList.remove("hidden");
      this.errorTarget.textContent =
        "Please add a title in order to generate a description";
      return;
    }

    const request = new FetchRequest("post", this.generateLinkPath, {
      headers: { accept: "application/json" },
      body: JSON.stringify({ line_item_title: this.lineItemTitle }),
    });

    const { response, ok } = await request.perform();
  }

  async improve() {
    if (this.textToImprove.trim() === "") {
      this.errorTarget.classList.remove("hidden");
      this.errorTarget.textContent = "Please add text to improve";
      return;
    }
    const request = new FetchRequest("post", this.improveLinkPath, {
      headers: { accept: "application/json" },
      body: JSON.stringify({ line_item_description: this.textToImprove }),
    });

    const { response, ok } = await request.perform();
  }
}
