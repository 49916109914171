import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  declare readonly formTarget: HTMLFormElement;

  handleBlur() {
    this.submit();
  }

  submitWithKeyboard(event: KeyboardEvent) {
    if (event.key === "Escape" || event.key === "Tab") {
      event.preventDefault();
      this.submit();
    }
  }

  submitWithBackgroundClick(event: MouseEvent) {
    if (!this.formTarget.contains(event.target as Node)) {
      this.submit();
    }
  }

  submit() {
    this.formTarget.requestSubmit();
  }
}
