import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { TurboSubmitEndEvent } from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["modal"];
  declare readonly modalTarget: HTMLDivElement;

  hideModal() {
    this.element.remove();
  }

  submitEnd(event: TurboSubmitEndEvent) {
    const detail = event.detail;

    if (detail.success) {
      this.hideModal();

      // For whatever reason, TypeScript isn't correctly importing
      // the types for TurboSubmitEndEvent, which clearly includes
      // FormSubmissionResult, which includes FetchResponse
      const fetchResponse = (event.detail as any).fetchResponse;
      if (fetchResponse.redirected) {
        // this enables redirecting out of the modal to a new page
        // e.g. supports it when the server just returns a redirect
        Turbo.visit(fetchResponse.response.url);
      }
    }
  }

  closeWithKeyboard(event: KeyboardEvent) {
    if (event.code == "Escape") {
      this.hideModal();
    }
  }

  closeBackground(event: MouseEvent) {
    if (event && this.modalTarget.contains(event.target as HTMLElement)) {
      return;
    }
    this.hideModal();
  }
}
