import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["buttonText", "menu", "field", "option"];
  declare readonly buttonTextTarget: HTMLElement;
  declare readonly menuTarget: HTMLElement;
  declare readonly fieldTarget: HTMLInputElement;
  declare readonly optionTargets: HTMLElement[];

  toggle() {
    this.menuTarget.classList.toggle("hidden");
  }

  windowClose(event: MouseEvent) {
    if (this.element.contains(event.target as HTMLElement)) {
      return;
    }

    this.menuTarget.classList.add("hidden");
  }

  clickOption(event: MouseEvent) {
    const target = event.target as HTMLElement;
    // find the nearest ancestor in the optionTargets array
    const nearestOptionTarget = this.optionTargets.find((optionTarget) =>
      optionTarget.contains(target),
    );
    if (!nearestOptionTarget) {
      return;
    }
    this.buttonTextTarget.innerHTML = nearestOptionTarget.innerHTML;
    this.fieldTarget.value = nearestOptionTarget.dataset.value || "";

    this.menuTarget.classList.add("hidden");
  }
}
