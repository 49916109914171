import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  async handleChange() {
    this.setTimeout(500);
  }

  async handleKeystroke() {
    this.setTimeout(1000);
  }

  setTimeout(timeoutLength: number) {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      const element = this.element as HTMLInputElement;
      element.form?.requestSubmit();
      this.timeoutId = null;
    }, timeoutLength);
  }
}
