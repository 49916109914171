import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.resize();
  }

  resize() {
    const textArea = this.element as HTMLTextAreaElement;
    textArea.style.height = "auto";
    // Set desired height to max(scroll height or 3 lines)
    const desiredHeight = Math.max(textArea.scrollHeight, 3 * 24);
    textArea.style.height = `${desiredHeight}px`;
    // Move cursor to the end of the text area
    textArea.selectionStart = textArea.selectionEnd = textArea.value.length;
  }
}
