import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="line-item-template-form-margin"
export default class extends Controller {
  static targets = [
    // targets for automatically calculating the cost and margin
    "costOutput",
    "marginOutput",
    "lintItemPrice",
    "materialCost",
    "materialQuantity",
    "lineItemQuantity",
    "costPerLineItemUnit",
    "costPerLineItemUnitCopy",
    // targets for margin modal
    "marginModel",
    "marginModalInput",
    "marginModelPrice",
  ];

  // targets for automatically calculating the cost and margin
  declare costOutputTarget: HTMLInputElement;
  declare marginOutputTarget: HTMLInputElement;
  declare lintItemPriceTarget: HTMLInputElement;
  declare materialCostTargets: HTMLInputElement[];
  declare materialQuantityTargets: HTMLInputElement[];
  declare lineItemQuantityTargets: HTMLInputElement[];
  declare costPerLineItemUnitTargets: HTMLElement[];
  declare costPerLineItemUnitCopyTargets: HTMLElement[];

  // targets for margin modal
  declare marginModelTarget: HTMLElement;
  declare marginModalInputTarget: HTMLInputElement;
  declare marginModelPriceTarget: HTMLElement;

  lineItemQuantityTargetDisconnected(event: Event): void {
    this.updateCostMargin();
  }

  showMarginModal(event: Event): void {
    event.preventDefault();
    this.marginModalInputTarget.value = "50";
    this.calculatePotentialPriceOnInput();
    this.marginModelTarget.classList.remove("hidden");
    this.marginModalInputTarget.focus();
  }

  hideMarginModal(event: Event): void {
    event.preventDefault();
    this.marginModelTarget.classList.add("hidden");
  }

  calculatePotentialPriceOnInput(): void {
    const potentialPrice = this.calculatePotentialPrice();

    if (isNaN(potentialPrice)) {
      this.marginModelPriceTarget.textContent = "$--.--";
    } else {
      this.marginModelPriceTarget.textContent = "$" + potentialPrice.toFixed(2);
    }
  }

  applyPotentialPrice(event: Event): void {
    event.preventDefault();
    const potentialPrice = this.calculatePotentialPrice();

    if (isNaN(potentialPrice)) {
      this.hideMarginModal(new Event("click"));
      return;
    }

    this.lintItemPriceTarget.value = potentialPrice.toFixed(2);
    this.lintItemPriceTarget.dispatchEvent(new Event("input"));
    this.updateCostMargin();
    this.hideMarginModal(new Event("click"));
  }

  calculatePotentialPrice(): number {
    const margin = parseFloat(this.marginModalInputTarget.value);
    const cost = this.calculateCost();
    const potentialPrice = cost / (1 - margin / 100);

    if (isNaN(potentialPrice) || !isFinite(potentialPrice) || margin >= 100) {
      return NaN;
    }

    return potentialPrice;
  }

  updateCostMargin(): void {
    const totalCost = this.calculateCost();

    // calculate the margin
    const lineItemPrice = parseFloat(this.lintItemPriceTarget.value);
    const totalMargin = ((lineItemPrice - totalCost) / lineItemPrice) * 100;

    // update the cost and margin output
    if (
      isNaN(totalCost) ||
      isNaN(totalMargin) ||
      !isFinite(totalCost) ||
      lineItemPrice === 0
    ) {
      this.costOutputTarget.textContent = "$--.--";
      this.marginOutputTarget.textContent = "--";
      return;
    }

    this.costOutputTarget.textContent = "$" + totalCost.toFixed(2);
    this.marginOutputTarget.textContent = totalMargin.toFixed(0) + "%";
  }

  private calculateCost(): number {
    // declare the variables total_cost, and total margin
    let totalCost = 0;

    let calculated = false;

    // iterate over each material cost and quantity
    this.materialCostTargets.forEach(
      (materialCost: HTMLElement, index: number) => {
        this.costPerLineItemUnitTargets[index].textContent = "$--.--";
        this.costPerLineItemUnitCopyTargets[index].textContent = "$--.--";
        // skip unless all materialCost, lineItemQuantity and materialQuantity are present
        if (
          !this.materialQuantityTargets[index] ||
          !this.lineItemQuantityTargets[index] ||
          !materialCost
        ) {
          return;
        }

        // assigns costPerUnit and check for nil values
        const costPerUnit = parseFloat(
          this.materialCostTargets[index].value || "0",
        );
        const material_quantity = parseFloat(
          this.materialQuantityTargets[index].value || "0",
        );
        const line_item_quantity = parseFloat(
          this.lineItemQuantityTargets[index].value || "0",
        );

        if (
          costPerUnit === 0 ||
          material_quantity === 0 ||
          line_item_quantity === 0
        ) {
          return;
        }

        calculated = true;

        const calculated_cost =
          (costPerUnit * material_quantity) / line_item_quantity;
        totalCost += calculated_cost;

        this.costPerLineItemUnitTargets[index].textContent =
          "$" + calculated_cost.toFixed(2);
        this.costPerLineItemUnitCopyTargets[index].textContent =
          this.costPerLineItemUnitTargets[index].textContent;
      },
    );

    if (calculated) {
      return totalCost;
    } else {
      return NaN;
    }
  }
}
