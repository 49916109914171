import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
export default class extends Controller {
  static targets = ["card", "interactiveElement"];
  declare readonly cardTarget: HTMLElement;
  declare readonly interactiveElementTargets: HTMLElement[];

  declare shouldFire: boolean;

  connect() {
    // Due to Turbo caching, the border we add before navigation may still be present
    // when we return to this page. Remove it on connect to prevent this.
    this.element.classList.remove("border-3", "border-indigo-600");
  }

  beginNavigateToWorkOrder(event: MouseEvent) {
    this.shouldFire = true;
  }

  cancelNavigateToWorkOrder(event: MouseEvent) {
    // This check prevents a very slight movement from canceling the click and feels smoother
    if (Math.abs(event.movementX) >= 1 || Math.abs(event.movementY) >= 1) {
      this.shouldFire = false;
    }
  }

  completeNavigateToWorkOrder(event: MouseEvent) {
    // Cancel if the user has moved their mouse since clicking
    if (!this.shouldFire) {
      return;
    }

    // return and follow default link behavior if the user
    // is clicking on a link within the card
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }

    // return if the element clicked is an interactive element or is within an interactive element
    if (
      this.interactiveElementTargets.some((element) =>
        element.contains(event.target as HTMLElement),
      )
    ) {
      return;
    }

    this.element.classList.add("border-3", "border-indigo-600");

    const url = this.cardTarget.dataset.url;
    if (url) {
      Turbo.visit(url);
    }
  }
}
