import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["additionalPhotosCheckbox", "additionalPhotosMessage"];

  declare readonly additionalPhotosCheckboxTarget: HTMLInputElement;
  declare readonly additionalPhotosMessageTarget: HTMLInputElement;

  toggleAdditionalPhotosMessage() {
    if (this.additionalPhotosCheckboxTarget.checked) {
      this.additionalPhotosMessageTarget.disabled = false;
      this.additionalPhotosMessageTarget.focus();
    } else {
      this.additionalPhotosMessageTarget.disabled = true;
    }
  }
}
