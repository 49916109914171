import { Controller } from "@hotwired/stimulus";

const ACTIVE_CLASSES =
  "inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";
const DISABLED_CLASSES =
  "inline-flex items-center justify-center rounded-md border border-gray-600 bg-white px-4 py-2 text-sm font-medium text-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2";

export default class extends Controller<HTMLFormElement> {
  static targets = ["field", "saveButton"];
  declare readonly fieldTarget: HTMLInputElement;
  declare readonly saveButtonTarget: HTMLButtonElement;
  initialValue: string | undefined;

  connect() {
    this.initialValue = this.fieldTarget.value;
  }

  textChanged() {
    if (this.fieldTarget.value == this.initialValue) {
      this.markAsClean();
    } else {
      this.markAsDirty();
    }
  }

  markAsClean() {
    // Can't save when it's clean
    this.saveButtonTarget.disabled = true;
    this.saveButtonTarget.className = DISABLED_CLASSES;
  }

  markAsDirty() {
    console.log("marking as dirty");
    this.saveButtonTarget.disabled = false;
    this.saveButtonTarget.className = ACTIVE_CLASSES;
  }
}
