import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label"];
  declare labelTargets: HTMLElement[];

  handleChange() {
    this.labelTargets.forEach((label) => {
      const radio = label.querySelector(
        "input[type='radio']",
      ) as HTMLInputElement;
      if (label && radio) {
        label.className = radio.checked
          ? "checked-radio-button-label"
          : "unchecked-radio-button-label";
      }
    });
  }
}
