import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expand", "collapse", "body", "padding"];
  declare readonly expandTarget: HTMLElement;
  declare readonly collapseTarget: HTMLElement;
  declare readonly bodyTarget: HTMLElement;
  declare readonly paddingTarget: HTMLElement;

  toggle() {
    this.expandTarget.classList.toggle("hidden");
    this.collapseTarget.classList.toggle("hidden");
    this.bodyTarget.classList.toggle("hidden");
    this.paddingTarget.classList.toggle("pb-2");
  }
}
