import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="line-item-template-search"
export default class extends Controller {
  static targets = ["input", "menu", "prompt", "preview"];
  declare readonly inputTarget: HTMLInputElement;
  declare readonly menuTarget: HTMLElement;
  declare readonly promptTarget: HTMLElement;
  declare readonly previewTarget: HTMLElement;

  connect() {
    this.close();
    this.updatePromptAndPreview();
  }

  open() {
    this.menuTarget.classList.remove("hidden");
  }

  close() {
    this.menuTarget.classList.add("hidden");
  }

  handleWindowKeyUp(event: KeyboardEvent) {
    if (event.code == "Escape" || event.code == "Tab") {
      this.close();
    }
  }

  handleWindowClick(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.menuTarget.contains(event.target as HTMLElement)
    ) {
      return;
    }
    this.close();
  }

  updatePromptAndPreview() {
    const value = this.inputTarget.value;
    this.previewTarget.textContent = value;

    if (!value) {
      this.previewTarget.classList.add("hidden");
      this.promptTarget.textContent = "Search for";
      return;
    }

    this.previewTarget.classList.remove("hidden");
  }
}
