import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "form"];
  declare readonly buttonTarget: HTMLLinkElement;
  declare readonly formTarget: HTMLFormElement;

  openForm() {
    this.buttonTarget.parentElement?.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
  }

  closeForm() {
    this.buttonTarget.parentElement?.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
    this.formTarget.reset();
  }
}
