import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLDivElement> {
  static targets = ["price", "quantity", "totalPrice"];
  declare readonly priceTarget: HTMLInputElement;
  declare readonly quantityTarget: HTMLInputElement;
  declare readonly totalPriceTarget: HTMLElement;

  set_total_price() {
    const price = parseFloat(this.priceTarget.value);
    const quantity = parseFloat(this.quantityTarget.value);
    if (isNaN(price) || isNaN(quantity)) {
      this.totalPriceTarget.innerHTML = "$0.00";
    } else {
      const total_price = price * quantity;
      // in for format of $12,000.00
      this.totalPriceTarget.innerHTML =
        "$" + total_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  }
}
