import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="line-item-template-form-unit"
export default class extends Controller {
  static targets = ["input", "output", "price1", "price2"];

  declare price1Target: HTMLInputElement;
  declare price2Target: HTMLInputElement;

  declare inputTarget: HTMLInputElement;
  declare outputTargets: HTMLElement[];

  connect(): void {
    this.updateFromInput();
    this.updatePrice2();
  }

  updateFromInput(): void {
    const value = this.inputTarget.value;
    this.outputTargets.forEach((target: HTMLElement) => {
      if (value === "") {
        target.textContent = "unit";
      } else {
        target.textContent = value;
      }
    });
  }

  updatePrice1(): void {
    this.price1Target.value = this.price2Target.value;
  }

  updatePrice2(): void {
    this.price2Target.value = this.price1Target.value;
  }
}
