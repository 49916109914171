import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["card"];
  declare readonly cardTarget: HTMLElement;

  declare shouldFire: boolean;

  beginNavigateToJob(event: MouseEvent) {
    this.shouldFire = true;
  }

  cancelNavigateToJob(event: MouseEvent) {
    // This check prevents a very slight movement from canceling the click and feels smoother
    if (Math.abs(event.movementX) >= 1 || Math.abs(event.movementY) >= 1) {
      this.shouldFire = false;
    }
  }

  completeNavigateToJob(event: MouseEvent) {
    // Cancel if the user has moved their mouse since clicking
    if (!this.shouldFire) {
      return;
    }

    // return and follow default link behavior if the user
    // is clicking on a link within the card
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }

    // otherwise, navigate to the job
    const url = this.cardTarget.dataset.url;
    if (url) {
      Turbo.visit(url);
    }
  }
}
