import { Controller } from "@hotwired/stimulus";
const CHECKED_WRAPPER_CLASSES =
  "flex gap-x-4 h-full items-center border-2 border-indigo-700 rounded bg-indigo-100 w-full px-2 py-4 shadow-sm";
const UNCHECKED_WRAPPER_CLASSES =
  "flex gap-x-4 h-full items-center border rounded border-gray px-2 py-4";

export default class extends Controller {
  static targets = [
    "sendViaEmailCheckbox",
    "sendViaSmsCheckbox",
    "emailPreview",
    "smsPreview",
    "emailWrapper",
    "smsWrapper",
  ];
  declare readonly sendViaEmailCheckboxTarget: HTMLInputElement;
  declare readonly sendViaSmsCheckboxTarget: HTMLInputElement;
  declare readonly emailPreviewTarget: HTMLElement;
  declare readonly smsPreviewTarget: HTMLElement;
  declare readonly emailWrapperTarget: HTMLElement;
  declare readonly smsWrapperTarget: HTMLElement;

  declare readonly hasSendViaEmailCheckboxTarget: boolean;
  declare readonly hasSendViaSmsCheckboxTarget: boolean;
  declare readonly hasEmailPreviewTarget: boolean;
  declare readonly hasSmsPreviewTarget: boolean;

  connect() {
    if (this.hasSendViaEmailCheckboxTarget) {
      this.handleSendViaEmailChange();
    }
    if (this.hasSendViaSmsCheckboxTarget) {
      this.handleSendViaSmsChange();
    }
  }

  handleSendViaEmailChange() {
    if (this.hasEmailPreviewTarget) {
      if (this.sendViaEmailCheckboxTarget.checked) {
        this.emailWrapperTarget.className = CHECKED_WRAPPER_CLASSES;
        this.emailPreviewTarget.classList.remove("hidden");
      } else {
        this.emailWrapperTarget.className = UNCHECKED_WRAPPER_CLASSES;
        this.emailPreviewTarget.classList.add("hidden");
      }
    }
  }

  handleSendViaSmsChange() {
    if (this.hasSmsPreviewTarget) {
      if (this.sendViaSmsCheckboxTarget.checked) {
        this.smsWrapperTarget.className = CHECKED_WRAPPER_CLASSES;
        this.smsPreviewTarget.classList.remove("hidden");
      } else {
        this.smsWrapperTarget.className = UNCHECKED_WRAPPER_CLASSES;
        this.smsPreviewTarget.classList.add("hidden");
      }
    }
  }
}
