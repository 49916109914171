import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller<HTMLAnchorElement> {
  static targets = [
    "email",
    "firstName",
    "lastName",
    "phone",
    "businessName",
    "source",
    "message",
  ];
  declare readonly emailTarget: HTMLInputElement;
  declare readonly firstNameTarget: HTMLInputElement;
  declare readonly lastNameTarget: HTMLInputElement;
  declare readonly phoneTarget: HTMLInputElement;
  declare readonly businessNameTarget: HTMLInputElement;
  declare readonly sourceTarget: HTMLInputElement;
  declare readonly messageTarget: HTMLDivElement;

  get email() {
    return this.emailTarget.value;
  }

  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  async handleEmailChange() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.fetchCustomer();
      this.timeoutId = null;
    }, 500);
  }

  private async fetchCustomer() {
    this.messageTarget.innerText = "";
    this.messageTarget.classList.add("hidden");

    if (this.email === "") {
      return;
    }

    const request = new FetchRequest(
      "get",
      "/customers/find_by_email?email=" + encodeURIComponent(this.email),
      {
        headers: { accept: "application/json" },
      },
    );

    const { response, ok } = await request.perform();
    if (ok) {
      const customer = await response.json();
      if (customer.found_customer) {
        const messageInnerHtml =
          "<span>A customer with that email already exists.</span>";

        this.messageTarget.innerHTML = messageInnerHtml;
        this.messageTarget.classList.remove("hidden");
        this.firstNameTarget.value = customer.first_name;
        this.lastNameTarget.value = customer.last_name;
        this.phoneTarget.value = customer.phone;
        this.businessNameTarget.value = customer.business_name;
        this.sourceTarget.value = customer.source;
      }
    }
  }
}
