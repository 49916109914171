import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "menu"];
  declare readonly buttonTarget: HTMLElement;
  declare readonly menuTarget: HTMLElement;

  private inFlight = false;

  toggle() {
    if (this.inFlight) {
      return;
    }

    this.menuTarget.classList.toggle("hidden");
  }

  setInFlight() {
    this.menuTarget.classList.add("hidden");
    this.inFlight = true;
  }

  windowClose(event: MouseEvent) {
    if (this.element.contains(event.target as HTMLElement)) {
      return;
    }

    this.menuTarget.classList.add("hidden");
  }
}
