import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const element = this.element as HTMLElement;

    // Scroll the element into view
    element.scrollIntoView();

    element.classList.remove("border-gray-300", "bg-slate-50");
    element.classList.add("bg-indigo-50", "border-indigo-600");

    // Then, wait 3 seconds and fade out by setting opacity to 0
    // and then removing the element from the DOM
    element.style.transition = "border-color 2s ease, background-color 2s ease";

    setTimeout(() => {
      element.classList.add("border-gray-300", "bg-slate-50");
      element.classList.remove("bg-indigo-50", "border-indigo-600");
    }, 3000);
  }
}
