import { Controller } from "@hotwired/stimulus";

// This controller is used to emulate select2 behavior in a form.
// You can populate the menu with whatever you need (for instance, links)
// and optionally use the selectOption method to populate the input field
export default class extends Controller {
  static targets = ["input", "menu", "option"];
  declare readonly inputTarget: HTMLInputElement;
  declare readonly menuTarget: HTMLElement;
  declare readonly optionTargets: HTMLElement[];
  declare readonly hasOptionTarget: boolean;

  get isOpen(): boolean {
    return !this.menuTarget.classList.contains("hidden");
  }

  open(event: Event) {
    this.filterMenuOptions(event);
    this.menuTarget.classList.remove("hidden");
  }

  close() {
    this.menuTarget.classList.add("hidden");
  }

  filterMenuOptions(event: Event) {
    const input = event.target as HTMLInputElement;
    const filter = input.value.toUpperCase();
    for (const item of this.menuTarget.children) {
      const itemText = item.textContent || "";
      const itemMatches = itemText.toUpperCase().indexOf(filter) > -1;
      item.classList.toggle("hidden", !itemMatches);
    }
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.isOpen) {
      if (event.code == "Escape") {
        this.close();
      } else if (event.code == "Tab") {
        event.preventDefault();
        this.tabToNext();
      } else if (event.code == "ArrowDown" && this.hasOptionTarget) {
        this.formArrowDown(event);
      } else if (event.code == "ArrowUp" && this.hasOptionTarget) {
        this.formArrowUp(event);
      } else if (event.code == "Enter" && this.hasOptionTarget) {
        this.selectOptionWithEnter(event);
      }
    }
  }

  closeBackground(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.menuTarget.contains(event.target as HTMLElement) ||
      !this.isOpen
    ) {
      return;
    }
    this.close();
  }

  // This method attempts to find the next input element in the form and focus it.
  // If there is no next input element, it does nothing.
  tabToNext() {
    if (this.inputTarget.form) {
      const formElements = Array.prototype.slice.call(
        this.inputTarget.form.elements,
      );
      const index = formElements.indexOf(this.inputTarget);
      const nextElement = formElements[index + 1];
      if (nextElement) {
        this.close();
        nextElement.focus();
      }
    }
  }

  // This is an optional method if the selectable text field is used
  // to emulate select2 behavior in a form.
  selectOption(event: Event) {
    const option = event.target as HTMLElement;
    this.inputTarget.value = option.textContent || "";
    this.inputTarget.dispatchEvent(new Event("change"));
    this.inputTarget.dispatchEvent(new Event("input"));
    this.close();
  }

  selectOptionWithEnter(event: Event) {
    event.preventDefault();

    const currentIndex = this.optionTargets.findIndex(
      (option) => option === document.activeElement,
    );

    if (currentIndex > 0) {
      this.inputTarget.value =
        this.optionTargets[currentIndex].textContent || "";
      this.inputTarget.dispatchEvent(new Event("change"));
      this.close();
      this.tabToNext();
    }
  }

  private focusNextOption(
    event: KeyboardEvent,
    getNextIndex: (currentIndex: number, length: number) => number,
  ) {
    // Default behavior is to scroll the page
    event.preventDefault();

    // Early return if mouse is hovering over menuTarget
    // Otherwise we focus on multiple items at once
    if (this.menuTarget.matches(":hover")) {
      return;
    }

    const currentIndex = this.optionTargets.findIndex(
      (option) => option === document.activeElement,
    );

    this.optionTargets[0].focus();
    if (currentIndex >= 0) {
      const nextIndex = getNextIndex(currentIndex, this.optionTargets.length);
      this.optionTargets[nextIndex].focus();
    } else if (this.optionTargets.length > 0) {
      this.optionTargets[0].focus();
    }
  }

  private formArrowDown(event: KeyboardEvent) {
    this.focusNextOption(
      event,
      (currentIndex, length) => (currentIndex + 1) % length,
    );
  }

  private formArrowUp(event: KeyboardEvent) {
    if (document.activeElement === this.optionTargets[0]) {
      this.inputTarget.focus();
    } else {
      this.focusNextOption(
        event,
        (currentIndex, length) => (currentIndex - 1 + length) % length,
      );
    }
  }
}
