import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "menu"];
  declare readonly inputTarget: HTMLInputElement;
  declare readonly menuTarget: HTMLElement;

  get isOpen(): boolean {
    return !this.menuTarget.classList.contains("hidden");
  }

  open(event: Event) {
    this.filterMenuOptions(event);
    this.menuTarget.classList.remove("hidden");
  }

  close() {
    this.menuTarget.classList.add("hidden");
  }

  filterMenuOptions(event: Event) {
    const input = event.target as HTMLInputElement;
    const filter = input.value.toLowerCase();
    for (const item of this.menuTarget.children) {
      const itemText = item.textContent || "";
      const itemMatches = itemText.toLowerCase().indexOf(filter) > -1;
      item.classList.toggle("hidden", !itemMatches);
    }
  }

  closeWithKeyboard(event: KeyboardEvent) {
    if (this.isOpen && event.code == "Escape") {
      this.menuTarget.classList.toggle("hidden");
    } else if (this.isOpen && event.code == "Tab") {
      this.menuTarget.classList.toggle("hidden");
      this.tabToNext();
    }
  }

  closeOnBackgroundEvent(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.menuTarget.contains(event.target as HTMLElement) ||
      !this.isOpen
    ) {
      return;
    }
    this.menuTarget.classList.toggle("hidden");
  }

  // This method attempts to find the next input element in the form and focus it.
  // If there is no next input element, it does nothing.
  tabToNext() {
    if (this.inputTarget.form) {
      const formElements = Array.prototype.slice.call(
        this.inputTarget.form.elements,
      );
      const index = formElements.indexOf(this.inputTarget);
      const nextElement = formElements[index + 1];
      if (nextElement) {
        nextElement.focus();
      }
    }
  }
}
