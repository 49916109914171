import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };
  declare urlValue: string;

  selectMaterial(event: CustomEvent) {
    // When a material is selected, we fetch an entirely new form
    // in order to populate the unit fields

    const selectedValue = event.detail.value;

    console.log(
      `Selected existing material usage. ID=${selectedValue}. Fetching...`,
    );

    const targetFrame = this.element?.closest("turbo-frame")?.id;
    const targetUrl = `${this.urlValue}?material_id=${selectedValue}`;

    Turbo.visit(targetUrl, {
      frame: targetFrame,
    });
  }
}
