// Entry point for the build script in your package.json

import "./app_signal";

import "chartkick/chart.js";

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";

import "./google_loader";

import "./controllers";

import "./hotwire_combobox";

import "./direct_uploads";
import "./disable_scroll_on_number_inputs";
import "./turbo_error_monitoring";
import "./turbo_streams_animations";

ActiveStorage.start();
