import type { TurboFrameMissingEvent } from "@hotwired/turbo";
import { appsignal } from "./app_signal";

// Sometimes, the browser may initiate a request from within a turbo frame;
// however, the server may respond with content that does not contain a
// matching frame ID. (Typically, this is a developer mistake.) When this
// happens, Turbo raises an event called `turbo:frame-missing`. We can listen
// for this event and report it.
//
// See `turbo:frame-missing` in the docs for full context:
// https://turbo.hotwired.dev/reference/events

document.addEventListener("turbo:frame-missing", (event) => {
  const {
    detail: { response },
  } = event as TurboFrameMissingEvent;

  const msg = `Turbo frame missing! Target URL: ${response.url}`;
  console.log(msg);
  appsignal.sendError(new Error(msg));

  // Note we could attempt to recover with lines below; however, I think
  // that would make local development & debugging quite tricky. I'm providing
  // these lines, nevertheless, to document this thinking.
  // event.preventDefault();
  // visit(response.url);
});
