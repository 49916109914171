import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLDivElement> {
  static targets = ["option"];
  declare readonly optionTargets: HTMLDivElement[];

  connect() {
    this.optionTargets.forEach((option) => {
      // if the option has a data-created-at and that date is within 10 seconds
      // and it's the last option in the array
      // give it an indigo border

      const createdAt = option.dataset.createdAt;
      if (!createdAt) return;

      if (
        new Date(createdAt) > new Date(new Date().getTime() - 10000) &&
        option === this.optionTargets[this.optionTargets.length - 1]
      ) {
        option.classList.add("border-indigo-500");
        // First, set the transition for all to two seconds
        option.style.transition = "all 3s ease";

        // Then, wait 3 seconds and remove the indigo border
        setTimeout(() => {
          option.classList.remove("border-indigo-500");
        }, 1000);

        // Find the position of the option
        const elementPosition = option.getBoundingClientRect().top;
        // Set the position of the offset by subtracting 100px from the top of the option
        const offsetPosition = elementPosition + window.scrollY - 100;
        // Smoothly scroll to the offset position
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    });

    window.addEventListener("click", this.removeIndigoBorder.bind(this));
  }

  removeIndigoBorder() {
    this.optionTargets.forEach((option) => {
      option.style.transition = "";
      option.classList.remove("border-indigo-500");
    });
  }
}
