import { Controller } from "@hotwired/stimulus";

const ACTIVE_CLASSES = "block px-2.5 py-1 text-sm text-gray-600";
const DISABLED_CLASSES =
  "block px-2.5 py-1 text-sm text-gray-300 pointer-events-none";

export default class extends Controller {
  static targets = ["collapsedContent", "expandedContent", "expandButton"];
  declare readonly collapsedContentTarget: HTMLElement;
  declare readonly expandedContentTarget: HTMLElement;
  declare readonly hasExpandButtonTarget: boolean;
  declare readonly expandButtonTarget: HTMLLinkElement;

  get isOpen(): boolean {
    return !this.expandedContentTarget.classList.contains("hidden");
  }

  toggle() {
    if (this.hasExpandButtonTarget) {
      if (this.isOpen) {
        this.expandButtonTarget.className = ACTIVE_CLASSES;
      } else {
        this.expandButtonTarget.className = DISABLED_CLASSES;
      }
    }
    this.collapsedContentTarget.classList.toggle("hidden");
    this.expandedContentTarget.classList.toggle("hidden");
  }
}
