import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private timer: NodeJS.Timeout | undefined;

  connect() {
    console.log("DashboardEventController#connect");

    const element = this.element as HTMLElement;

    this.timer = setTimeout(() => {
      element.classList.remove("duration-100");
      element.classList.add("duration-1000");
      // css transitions don't work on any "auto" properties, so we
      // specify ring-0 to get the transition to work.
      element.classList.add("ring-0");
    }, 3000);
  }

  // clean up in case of quick navigation
  disconnect(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
