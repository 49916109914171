import { Application } from "@hotwired/stimulus";

import { installErrorHandler } from "@appsignal/stimulus";
import { appsignal } from "../app_signal";

const application = Application.start();

installErrorHandler(appsignal, application);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
