import { Controller } from "@hotwired/stimulus";
import Photoswipe from "photoswipe";
// The types for this library are out of date
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PhotoSwipeLightbox from "photoswipe/lightbox";

import "photoswipe/dist/photoswipe.css";

export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '*[data-controller="lightbox"]',
      children: "a",
      initialZoomLevel: "fit",
      pswpModule: Photoswipe,
    });
    lightbox.init();
  }
}
