import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };
  declare urlValue: string;

  selectLineItem(event: CustomEvent) {
    // Funny issue here, where it is not immediately clear
    // whether an existing item has been select or if a new item has been created
    //
    // If it is a new record, value = the string, i.e "Attic Insulation"
    // if it is an existing record, value = the id, i.e 1
    //
    // Therefore, if the value is a number, it is an existing record.
    // However, this does leave a potential bug where someone could try
    // to create an item called "1" and it would be treated as an existing record.

    const selectedValue = event.detail.value;

    if (selectedValue == "") {
      return;
    }

    const selectedValueAsNumber = Number(selectedValue);
    if (Number.isNaN(selectedValueAsNumber)) {
      return;
    }

    console.log(
      `Selected existing line item template. ID=${selectedValue}. Fetching...`,
    );

    const targetFrame = this.element?.closest("turbo-frame")?.id;
    const targetUrl = `${this.urlValue}?line_item_template_id=${selectedValue}`;

    Turbo.visit(targetUrl, {
      frame: targetFrame,
    });
  }
}
