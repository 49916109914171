import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["select"];
  declare readonly selectTarget: HTMLSelectElement;
  declare readonly hasSelectTarget: boolean;

  connect() {
    if (this.hasSelectTarget) {
      this.displayDownPaymentOfSelected();
    }
  }

  displayDownPaymentOfSelected() {
    const optionId = this.selectTarget.value;

    const allDownPaymentValues = this.element.getElementsByClassName(
      "option-down-payment-value",
    );

    Array.from(allDownPaymentValues).forEach((paymentValue) => {
      paymentValue.classList.add("hidden");
    });

    const selectedDownPaymentValue = this.element.querySelector(
      `#modal-option-down-payment-${optionId}`,
    );

    if (selectedDownPaymentValue) {
      selectedDownPaymentValue.classList.remove("hidden");
    }
  }
}
