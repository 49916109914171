import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toast"];
  declare readonly toastTarget: HTMLElement;

  copy() {
    const button = this.element as HTMLButtonElement;

    const link = button.dataset.link as string;
    navigator.clipboard.writeText(link);

    // flash green to indicate success
    this.toastTarget.classList.remove("hidden");
    this.toastTarget.style.transition = "opacity 1s ease";
    setTimeout(() => {
      this.toastTarget.style.opacity = "0";
      setTimeout(() => {
        this.toastTarget.style.opacity = "1";
        this.toastTarget.classList.add("hidden");
      }, 1000);
    }, 1000);
  }
}
