// Number fields increment when you scroll. This can be annoying when you're
// trying to scroll the page. This script disables scrolling on number inputs

// Rather than add an event listener to every input element, we can add a single
// event listener to the document to increase performance.
document.addEventListener("wheel", function () {
  // If there's an active element, the active element is an input, and the
  // input is of type "number", then blur the input.
  if (
    document.activeElement &&
    document.activeElement.tagName.toUpperCase() === "INPUT"
  ) {
    const input = document.activeElement as HTMLInputElement;
    if (input.type === "number") {
      input.blur();
    }
  }
});
