import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller<HTMLAnchorElement> {
  static targets = ["textToImprove", "improveLink"];
  declare readonly textToImproveTarget: HTMLInputElement;
  declare readonly improveLinkTarget: HTMLLinkElement;

  connect() {
    this.textToImproveTarget.focus();
    const len = this.textToImproveTarget.value.length;
    this.textToImproveTarget.setSelectionRange(len, len);
  }

  get textToImprove() {
    return this.textToImproveTarget.value;
  }

  get improveLinkPath() {
    return this.improveLinkTarget.href;
  }

  async onClick() {
    const request = new FetchRequest("post", this.improveLinkPath, {
      headers: { accept: "application/json" },
      body: JSON.stringify({ prompt_input: this.textToImprove }),
    });

    const { response, ok } = await request.perform();
  }
}
