import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLDivElement> {
  static targets = ["primaryButton", "splitButtonMenu"];
  declare readonly splitButtonMenuTarget: HTMLDivElement;
  declare readonly primaryButtonTarget: HTMLFormElement;

  get isOpen(): boolean {
    return !this.splitButtonMenuTarget.classList.contains("hidden");
  }

  splitButtonMenuTargetConnected() {
    this.splitButtonMenuTarget.childNodes.forEach((node) => {
      if (node instanceof HTMLFormElement) {
        node.addEventListener("submit", this.disablePrimaryAfterSecondaryClick);
      }
    });
  }

  disablePrimaryAfterSecondaryClick = () => {
    this.primaryButtonTarget.setAttribute("disabled", "true");
    setTimeout(
      () => this.primaryButtonTarget.removeAttribute("disabled"),
      1000,
    );
  };

  toggle() {
    if (this.isOpen) {
      this.splitButtonMenuTarget.classList.add("hidden");
    } else {
      this.splitButtonMenuTarget.classList.remove("hidden");
    }
  }

  closeWithKeyboard(event: KeyboardEvent) {
    if (this.isOpen && event.code == "Escape") {
      this.toggle();
    }
  }

  closeBackground(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.splitButtonMenuTarget.contains(event.target as HTMLElement) ||
      !this.isOpen
    ) {
      return;
    }
    this.toggle();
  }
}
