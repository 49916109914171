import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hide() {
    this.element.remove();
  }

  connect() {
    const element = this.element as HTMLElement;
    // First, set the transition for opacity to two seconds
    element.style.transition = "opacity 1s ease";

    // Then, wait 3 seconds and fade out by setting opacity to 0
    // and then removing the element from the DOM
    setTimeout(() => {
      element.style.opacity = "0";
      setTimeout(() => {
        this.element.remove();
      }, 2000);
    }, 3000);
  }
}
