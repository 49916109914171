import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["insertBefore", "template", "wrapper"];

  declare readonly insertBeforeTarget: HTMLElement;
  declare readonly templateTarget: HTMLElement;
  declare readonly wrapperSelectorValue: string;

  static values = {
    wrapperSelector: {
      type: String,
      default: ".material-requirement-nested-form-wrapper",
    },
  };

  add(e: Event) {
    e.preventDefault();

    const content: string = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString(),
    );
    this.insertBeforeTarget.insertAdjacentHTML("beforebegin", content);
  }

  remove(e: Event): void {
    if (!e.target) return;

    e.preventDefault();

    const wrapper: HTMLElement | null = (e.target as HTMLElement).closest(
      this.wrapperSelectorValue,
    );

    if (!wrapper) return;

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";

      const input: HTMLInputElement | null = wrapper.querySelector(
        "input[name*='_destroy']",
      );
      if (input) {
        (input as HTMLInputElement).value = "1";
      }

      // remove line_item_quantity input to trigger margin recalculation
      const line_item_quantity: HTMLInputElement | null = wrapper.querySelector(
        "input[id*='_line_item_quantity']",
      );
      if (line_item_quantity) {
        line_item_quantity.remove();
      }
    }
  }
}
