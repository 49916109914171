import { Controller } from "@hotwired/stimulus";
import type { FrameElement } from "@hotwired/turbo";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    subscriptionsUrl: String,
    newSubscriptionUrl: String,
    transitionIn: Boolean,
    closeAfterTap: Boolean,
  };
  declare readonly subscriptionsUrlValue: string;
  declare readonly newSubscriptionUrlValue: string;
  declare readonly transitionInValue: boolean;
  declare readonly hasTransitionInValue: boolean;
  declare readonly closeAfterTapValue: boolean;
  declare readonly hasCloseAfterTapValue: boolean;

  static targets = ["closeButton", "subscribeTextSpan"];
  declare readonly closeButtonTarget: HTMLElement;
  declare readonly hasCloseButtonTarget: boolean;
  declare readonly subscribeTextSpanTarget: HTMLElement;
  declare readonly hasSubscribeTextSpanTarget: boolean;

  connect() {
    if (this.hasSubscribeTextSpanTarget && this.#isPWA) {
      this.subscribeTextSpanTarget.innerHTML =
        '<span class="underline">Tap here</span> to allow notifications.';
    }

    if (!this.hasTransitionInValue && this.transitionInValue) {
      return;
    }
    // slide the banner down from top using class names
    const element = this.element as HTMLElement;

    setTimeout(() => {
      element.classList.remove("-translate-y-48");
      element.classList.add("translate-y-20", "shadow-lg");
    }, 1500);
  }

  removeSetupSolicitation() {
    // 90 days in milliseconds
    const expiry = 90 * 24 * 60 * 60 * 1000;
    const body = ["notification-solicitation-cleared", Date.now()]
      .map(encodeURIComponent)
      .join("=");
    const expires = new Date(Date.now() + expiry).toUTCString();
    const cookie = `${body}; path=/; expires=${expires}`;
    document.cookie = cookie;

    this.element.remove();
  }

  #storeSuccessfulSubscription() {
    // 20 years in milliseconds
    const expiry = 20 * 365 * 24 * 60 * 60 * 1000;
    const body = ["notifications-subscribed", Date.now()]
      .map(encodeURIComponent)
      .join("=");
    const expires = new Date(Date.now() + expiry).toUTCString();
    const cookie = `${body}; path=/; expires=${expires}`;
    document.cookie = cookie;

    this.element.remove();
  }

  async startOrCompleteSubscription(event: MouseEvent) {
    if (this.#allowed) {
      const registration =
        (await this.#serviceWorkerRegistration) ||
        (await this.#registerServiceWorker());

      switch (Notification.permission) {
        case "denied": {
          this.#openSubscriptionModal(event, true);
          break;
        }
        case "granted": {
          this.#subscribe(registration);
          break;
        }
        case "default": {
          this.#requestPermissionAndSubscribe(registration);
        }
      }
    } else {
      this.#openSubscriptionModal(event);
    }
  }

  async isEnabled() {
    if (this.#allowed) {
      const registration = await this.#serviceWorkerRegistration;
      const existingSubscription =
        await registration?.pushManager?.getSubscription();

      return (
        Notification.permission == "granted" &&
        registration &&
        existingSubscription
      );
    } else {
      return false;
    }
  }

  get #allowed() {
    return navigator.serviceWorker && window.Notification;
  }

  get #serviceWorkerRegistration() {
    return navigator.serviceWorker.getRegistration(window.location.origin);
  }

  #registerServiceWorker() {
    return navigator.serviceWorker.register("/service-worker.js");
  }

  #openSubscriptionModal(event: MouseEvent, denied?: boolean) {
    // return if the element clicked is an interactive element or is within an interactive element
    if (
      this.hasCloseButtonTarget &&
      this.closeButtonTarget.contains(event.target as HTMLElement)
    ) {
      return;
    }

    const element = this.element as HTMLElement;
    const url = this.newSubscriptionUrlValue;

    const frame = document.querySelector("turbo-frame#modal") as FrameElement;
    if (!frame) return;

    if (denied) {
      frame.src = url + "?denied=true";
    } else {
      frame.src = url;
    }

    if (this.hasCloseAfterTapValue && this.closeAfterTapValue) {
      this.element.remove();
    }
  }

  #subscribe(registration: ServiceWorkerRegistration) {
    registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.#vapidPublicKey,
      })
      .then((subscription) => {
        this.#syncPushSubscription(subscription);
      });
  }

  async #syncPushSubscription(subscription: PushSubscription) {
    const url = this.subscriptionsUrlValue;
    const response = await post(url, {
      body: this.#formattedSubscriptionPayload(subscription),
      responseKind: "turbo-stream",
    });
    if (response.ok) {
      this.#storeSuccessfulSubscription();
    } else {
      subscription.unsubscribe();
    }
  }

  async #requestPermissionAndSubscribe(
    registration: ServiceWorkerRegistration,
  ) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") this.#subscribe(registration);
  }

  get #vapidPublicKey() {
    const encodedVapidPublicKeyMetaElement = document.querySelector(
      'meta[name="vapid-public-key"]',
    ) as HTMLMetaElement;
    const encodedVapidPublicKey = encodedVapidPublicKeyMetaElement?.content;

    return this.#urlBase64ToUint8Array(encodedVapidPublicKey);
  }

  #formattedSubscriptionPayload(subscription: PushSubscription) {
    const subscriptionJson = subscription.toJSON();
    const { endpoint } = subscriptionJson;
    const p256dh = subscriptionJson.keys?.p256dh;
    const auth = subscriptionJson.keys?.auth;

    return {
      push_subscription: { endpoint, p256dh_key: p256dh, auth_key: auth },
    };
  }

  // VAPID public key comes encoded as base64 but service worker registration needs it as a Uint8Array
  #urlBase64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  get #isPWA() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }
}
