import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["canvas", "signature"];
  declare readonly canvasTarget: HTMLCanvasElement;
  declare readonly signatureTarget: HTMLInputElement;

  declare signaturePad: SignaturePad;

  connect() {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      this.signaturePad = new SignaturePad(canvas);
      this.resizeCanvas();

      // store the signature in the hidden input as SVG
      this.signaturePad.addEventListener("afterUpdateStroke", () => {
        this.signatureTarget.value = this.signaturePad.toSVG();
      });
    }

    window.addEventListener("resize", this.resizeCanvas.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeCanvas.bind(this));
  }

  resizeCanvas() {
    // This was taken from the signature_pad documentation, and
    // is required to look good on mobile devise.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    const context = this.canvasTarget.getContext("2d");
    if (context) {
      context.scale(ratio, ratio);
    }

    this.signaturePad.clear();
  }
}
