import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["container"];
  declare readonly containerTarget: HTMLElement;
  declare sortable: Sortable;

  connect() {
    const enabled =
      (this.element as HTMLElement).dataset.sortEnabled === "true";

    this.sortable = new Sortable(this.containerTarget, {
      disabled: !enabled,
      dragClass: "sortable-drag",
      ghostClass: "sortable-ghost",
      handle: ".sortable-handle",
      onEnd: this.onEnd.bind(this),
    });
  }

  disconnect() {
    this.sortable.destroy;
  }

  onEnd(event: Sortable.SortableEvent) {
    const { newIndex, item } = event;
    const url = item.dataset.updateSequenceUrl;
    if (!url) {
      throw new Error(
        "SortableLineItemsController#onEnd Missing data-update-sequence-url",
      );
    }

    put(url, {
      responseKind: "turbo-stream",
      body: {
        sequenceable: {
          sequence: (newIndex || 0) + 1,
        },
      },
    });
  }
}
