import { Controller } from "@hotwired/stimulus";

export default class SimpleDropdownController<
  ElementType extends Element = Element,
> extends Controller<ElementType> {
  static targets = ["menu", "button"];
  declare readonly menuTarget: HTMLElement;
  declare readonly buttonTarget: HTMLElement;

  connect() {
    this.menuTarget.classList.add("hidden");
  }

  get isOpen(): boolean {
    return !this.menuTarget.classList.contains("hidden");
  }

  // This component is inherited by other components which use the event param
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggle(_event: MouseEvent) {
    // Due to Turbo caching, the dropdown will still be open
    // when we return to this page. Remove it on connect to prevent this.
    this.menuTarget.classList.toggle("hidden");
  }

  closeWithKeyboard(event: KeyboardEvent) {
    if (this.isOpen && event.code == "Escape") {
      this.menuTarget.classList.toggle("hidden");
      this.buttonTarget.blur();
    }
  }

  closeBackground(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.menuTarget.contains(event.target as HTMLElement) ||
      !this.isOpen
    ) {
      return;
    }
    this.menuTarget.classList.toggle("hidden");
  }

  closeMenuOnClick(event: MouseEvent) {
    if (this.isOpen) {
      this.toggle(event);
    }
  }
}
