import { Controller } from "@hotwired/stimulus";

// To use, assign this controller to the label of your checkbox (where you want the user to be able to tap)
// and assign the checkbox to the checkbox target.
export default class extends Controller {
  static targets = ["checkbox"];
  declare readonly checkboxTarget: HTMLInputElement;

  handleChange() {
    const element = this.element as HTMLElement;
    if (this.checkboxTarget.checked) {
      element.className = "checked-checkbox-label";
    } else {
      element.className = "unchecked-checkbox-label";
    }
    if (this.checkboxTarget.dataset.autoSubmit === "true") {
      this.checkboxTarget.form?.requestSubmit();
    }
  }
}
