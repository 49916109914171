import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="anchor"
export default class extends Controller {
  connect() {
    const current_url = window.location.href;
    const id = current_url.split("#")[1];
    if (!id) {
      return;
    }

    const target = document.querySelector("#" + id);

    if (target) {
      target.scrollIntoView();
    }
  }
}
