import { Controller } from "@hotwired/stimulus";

// Toggles between two groups of elements with an either-or relationship.
export default class extends Controller {
  static targets = ["group1", "group2"];
  declare readonly group1Target: HTMLSpanElement;
  declare readonly group2Target: HTMLInputElement;

  toggle() {
    this.group1Target.classList.toggle("hidden");
    this.group2Target.classList.toggle("hidden");
  }
}
