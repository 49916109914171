import SimpleDropdownController from "./simple_dropdown_controller";

export default class extends SimpleDropdownController<HTMLSelectElement> {
  static targets = ["menu", "button"];
  declare readonly menuTarget: HTMLElement;
  declare readonly buttonTarget: HTMLSelectElement;

  toggle(event: MouseEvent) {
    if (
      !navigator.userAgent.match(/Android/i) &&
      !navigator.userAgent.match(/webOS/i) &&
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/BlackBerry/i) &&
      !navigator.userAgent.match(/Windows Phone/i)
    ) {
      event.preventDefault();
      this.buttonTarget.focus();
      this.menuTarget.classList.toggle("hidden");

      // There is a blank option at the beginning of the select element (buttonTarget)
      // that is not present in our styled options (contained in the menuTarget), so we
      // need to subtract 1 from the selected index to get the correct index for the menuTarget.
      // Context:
      //   https://github.com/rails/rails/pull/20124
      //   https://html.spec.whatwg.org/multipage/form-elements.html#attr-select-required
      const index = this.buttonTarget.selectedIndex - 1;

      // If the selected index is -1, then the user has not selected anything, so
      // there is nothing to scroll to.
      console.log(index);
      if (index != -1) {
        const selectedTime = this.menuTarget.children[index];
        selectedTime.scrollIntoView();
      }
    }
  }

  select(event: MouseEvent) {
    const index = (event.target as HTMLDivElement).getAttribute("index");
    if (index !== null && !isNaN(Number(index))) {
      this.buttonTarget.selectedIndex = Number(index);
    }
    this.toggle(event);
  }
}
