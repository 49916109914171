import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "label", "row"];
  declare readonly checkboxTarget: HTMLInputElement;
  declare readonly labelTarget: HTMLElement;
  declare readonly rowTarget: HTMLElement;

  connect() {
    this.updateLabel();
    this.updateStyles();
  }

  toggle() {
    this.updateLabel();
    this.updateStyles();
  }

  updateLabel() {
    if (this.checkboxTarget.checked) {
      this.labelTarget.textContent = "Included";
      this.labelTarget.classList.remove("italic");
    } else {
      this.labelTarget.textContent = "Excluded";
      this.labelTarget.classList.add("italic");
    }
  }

  updateStyles() {
    if (this.checkboxTarget.checked) {
      this.rowTarget.classList.remove("text-slate-400");
    } else {
      this.rowTarget.classList.add("text-slate-400");
    }
  }
}
