import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hidable"];
  declare readonly hidableTarget: HTMLElement;

  toggle() {
    this.hidableTarget.classList.toggle("hidden");
  }
}
