import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLAnchorElement> {
  static targets = ["textAreaToFocus"];
  declare readonly textAreaToFocusTarget: HTMLInputElement;

  connect() {
    this.textAreaToFocusTarget.focus();
    const len = this.textAreaToFocusTarget.value.length;
    this.textAreaToFocusTarget.setSelectionRange(len, len);
  }
}
