import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textDiv"];
  declare readonly textDivTarget: HTMLElement;

  copy() {
    const button = this.element as HTMLButtonElement;

    const link = button.dataset.link as string;
    navigator.clipboard.writeText(link);

    this.textDivTarget.innerText = "Copied";

    setTimeout(() => {
      this.textDivTarget.innerText = "Copy";
    }, 2000);
  }
}
