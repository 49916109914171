import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filter-nav"
export default class extends Controller {
  static targets = ["filter", "open", "close"];
  declare readonly filterTarget: HTMLElement;
  declare readonly openTarget: HTMLElement;
  declare readonly closeTarget: HTMLElement;
  declare readonly hasFilterTarget: boolean;

  disconnect() {
    this.close();
  }

  open() {
    if (!this.hasFilterTarget) {
      return;
    }

    this.filterTarget.classList.remove("-translate-x-full");
    this.openTarget.classList.add("hidden");
    this.closeTarget.classList.remove("hidden");
  }

  close() {
    if (!this.hasFilterTarget) {
      return;
    }

    this.filterTarget.classList.add("-translate-x-full");
    this.openTarget.classList.remove("hidden");
    this.closeTarget.classList.add("hidden");
  }

  windowClose(event: MouseEvent) {
    if (!this.hasFilterTarget) {
      return;
    }

    if (
      this.filterTarget.contains(event.target as HTMLElement) ||
      this.openTarget.contains(event.target as HTMLElement) ||
      this.closeTarget.contains(event.target as HTMLElement)
    ) {
      return;
    }

    this.close();
  }
}
