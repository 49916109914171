import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cc", "link"];
  declare readonly ccTarget: HTMLElement;
  declare readonly linkTarget: HTMLElement;

  reveal() {
    this.ccTarget.classList.remove("hidden");
    this.linkTarget.classList.add("hidden");
  }
}
