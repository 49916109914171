// Add a class to an element we are about to remove from the page
// as defined by its "data-stream-exit-class"
document.addEventListener(
  "turbo:before-stream-render",
  function (event: Event) {
    const customEvent = event as CustomEvent & { target: { target: string } };

    if (customEvent.detail && typeof customEvent.detail.render === "function") {
      customEvent.detail.render = function (streamElement: any) {
        if (streamElement.action == "remove") {
          const targetId = customEvent.target?.target;
          if (targetId) {
            const elementToRemove = document.getElementById(targetId);
            if (elementToRemove) {
              const streamExitClass = elementToRemove.dataset.streamExitClass;
              if (streamExitClass) {
                // Intercept the removal of the element
                customEvent.preventDefault();
                elementToRemove.classList.add(streamExitClass);
                // Wait for its animation to end before removing the element
                elementToRemove.addEventListener("animationend", function () {
                  (customEvent.target as any).performAction();
                });
              } else {
                (customEvent.target as any).performAction();
              }
            }
          }
        } else {
          (customEvent.target as any).performAction();
        }
      };
    }
  },
);
