// This file is a counterpart to the `address_controller.ts` file. It loads
// the Google Maps API and dispatches an event when it's ready.

import { Loader, LoaderOptions } from "google-maps";
import { appsignal } from "./app_signal";

const apiKey = document.querySelector<HTMLMetaElement>(
  'meta[name="google_maps"]',
)?.content;
if (!apiKey) {
  appsignal.sendError(new Error("Missing Google Maps API key"));
}
const options: LoaderOptions = { libraries: ["places"] };
const loaderPromise = new Loader(apiKey, options).load();

loaderPromise.then(() => {
  const initMapEvent = new CustomEvent("google-maps-callback");
  console.log("Dispatching google-maps-callback event");
  window.dispatchEvent(initMapEvent);
});
