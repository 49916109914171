import { Controller } from "@hotwired/stimulus";

// https://gorails.com/episodes/google-maps-places-autocomplete-with-rails
// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
//
// See `google_loader.ts` for how the Google Maps API is loaded,
// and for the firing of the customer event `google-maps-callback`
export default class extends Controller<HTMLFormElement> {
  static targets = [
    "streetAddress",
    "streetAddressLineTwo",
    "city",
    "state",
    "zip",
  ];
  declare readonly streetAddressTarget: HTMLInputElement;
  declare readonly streetAddressLineTwoTarget: HTMLInputElement;
  declare readonly cityTarget: HTMLInputElement;
  declare readonly stateTarget: HTMLInputElement;
  declare readonly zipTarget: HTMLInputElement;

  declare autocomplete: google.maps.places.Autocomplete;

  connect() {
    // guard against race condition where google maps is loaded
    console.log("AddressController connect");
    if (window.google) {
      this.initMap();
    }
  }

  keydown(event: KeyboardEvent) {
    // Allow end-user to select an address without submitting
    // the form
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }

  initMap() {
    console.log("AddressController initMap");
    this.autocomplete = new google.maps.places.Autocomplete(
      this.streetAddressTarget,
    );

    this.autocomplete.setFields(["address_components"]);

    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this),
    );
  }
  placeChanged() {
    console.log("AddressController placeChanged callback");
    const place = this.autocomplete.getPlace();
    const addrComponents = place.address_components;

    if (!addrComponents) {
      return;
    }

    const streetNumber = this.findAddrComponent(
      addrComponents,
      "street_number",
    );
    const route = this.findAddrComponent(addrComponents, "route");
    this.streetAddressTarget.value = `${streetNumber} ${route}`;

    this.cityTarget.value = this.findAddrComponent(addrComponents, "locality");
    if (this.cityTarget.value == "") {
      this.cityTarget.value = this.findAddrComponent(
        addrComponents,
        "sublocality",
      );
    }
    if (this.cityTarget.value == "") {
      this.cityTarget.value = this.findAddrComponent(
        addrComponents,
        "administrative_area_level_3",
      );
    }
    this.stateTarget.value = this.findAddrComponent(
      addrComponents,
      "administrative_area_level_1",
    );
    this.zipTarget.value = this.findAddrComponent(
      addrComponents,
      "postal_code",
    );
  }

  findAddrComponent(
    components: google.maps.GeocoderAddressComponent[],
    componentType: string,
  ): string {
    const val = components.find((component) => {
      return component.types.includes(componentType);
    })?.short_name;

    return val || "";
  }
}
