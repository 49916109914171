import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  activate() {
    const clickedElement = this.element;
    const clickableLink = clickedElement.querySelector(
      "a[data-expand-hit-box='true']",
    );

    if (clickableLink instanceof HTMLElement) {
      clickableLink.click();
    }
  }
}
