import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "button"];
  declare readonly menuTarget: HTMLElement;
  declare readonly buttonTarget: HTMLElement;

  private inFlight = false;

  toggle() {
    if (this.inFlight) {
      return;
    }

    this.menuTarget.classList.toggle("hidden");
    this.buttonTarget.classList.toggle("rounded-b-none");
  }

  setInFlight() {
    if (this.inFlight) {
      return;
    }
    this.menuTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("rounded-b-none");
    this.inFlight = true;
    this.setDisplayStateToSaving();
  }

  setDisplayStateToSaving() {
    // The display state is a child of the buttonTarget, so it cannot
    // also be a stimulus target. Therefore, use good ole querySelector
    const element = this.buttonTarget.querySelector(".display-state");
    if (element) {
      element.textContent = "Saving...";
    }
  }

  windowClose(event: MouseEvent) {
    if (this.element.contains(event.target as HTMLElement)) {
      return;
    }

    this.menuTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("rounded-b-none");
  }
}
