import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bar", "cross", "menu"];
  declare readonly barTarget: HTMLElement;
  declare readonly crossTarget: HTMLElement;
  declare readonly menuTarget: HTMLElement;

  get isOpen(): boolean {
    return !this.menuTarget.classList.contains("hidden");
  }

  toggle() {
    this.barTarget.classList.toggle("hidden");
    this.crossTarget.classList.toggle("hidden");
    this.menuTarget.classList.toggle("hidden");
    const element = document.activeElement;
    if (element instanceof HTMLElement) {
      element.blur();
    }
  }

  closeBackground(event: MouseEvent) {
    if (
      this.element.contains(event.target as HTMLElement) ||
      this.menuTarget.contains(event.target as HTMLElement) ||
      !this.isOpen
    ) {
      return;
    }
    this.toggle();
  }

  closeWithKeyboard(event: KeyboardEvent) {
    if (this.isOpen && event.code == "Escape") {
      this.toggle();
    }
  }

  closeMenuOnClick() {
    if (this.isOpen) {
      this.toggle();
    }
  }
}
